import { createAction, handleActions } from 'redux-actions';
import { path } from 'lodash/fp';
import { createSelector } from 'reselect';
import { RealtimeAction } from '../store/realtime';
import { END_USER_URL } from '../components/config';
import last from 'lodash/last';

const defaultState = {
  user: null,
  accessToken: '',
  reloadRequired: false,
  reloadReason: 'role-changed', // role-changed | plan-changed
};

// actions
export class AuthUserAction {
  static setUser = createAction('SET_USER');
  static removeUser = createAction('REMOVE_USER');
  static setAccessToken = createAction('SET_ACCESS_TOKEN');
}

const getDomainName = (email) => last(email.split('@')).toLowerCase();

// selectors
export class AuthUserSelector {
  static getUser = path('authUser.user');
  static getAccessToken = path('authUser.accessToken');
  static getOrganization = path('authUser.user.organization');
  static getPlan = path('authUser.user.plan');
  static getOriginalOrganization = path('authUser.user.originalOrganization');

  static getOrgId = createSelector(
    AuthUserSelector.getOrganization,
    (org) => org._id
  );

  static getOrgDomainName = createSelector(AuthUserSelector.getUser, (user) => {
    if (!user) {
      return '';
    }

    return getDomainName(user.email);
  });

  static isAuthenticated = createSelector(AuthUserSelector.getUser, (user) =>
    Boolean(user && !!user._id)
  );

  static isFreePlan = createSelector(
    AuthUserSelector.getPlan,
    (plan) => plan !== 'pro'
  );

  static isDemo = createSelector(
    AuthUserSelector.getUser,
    (user) => user && user.level === 'demo'
  );

  static isAdmin = createSelector(
    AuthUserSelector.getUser,
    (user) => user.role === 'organization_admin'
  );

  static isSuperAdmin = createSelector(
    AuthUserSelector.getUser,
    (user) =>
      user.email === 'long@podzim.co' || user.email === 'adam@backdrop.io'
  );

  static isMember = createSelector(
    AuthUserSelector.getUser,
    (user) => user.role === 'member'
  );

  static isCollaborator = createSelector(
    AuthUserSelector.getUser,
    (user) => user.role === 'collaborator'
  );

  static getStickyFiles = path('authUser.user.stickyFiles');

  static getUserId = path('authUser.user._id');

  static isReloadRequired = path('authUser.reloadRequired');

  static getReloadReason = path('authUser.reloadReason');

  static getFeatures = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (!org) {
        return {};
      }

      return org.features || {};
    }
  );

  static getUploadConfig = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (!org) {
        return {};
      }

      return org.uploadConfig || {};
    }
  );

  static getViewerUrl = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (org && org.enableCustomDomainName) {
        return org.customDomainName;
      }

      return END_USER_URL;
    }
  );

  static getFreeSignal = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (org && org.freeSignal) {
        return org.freeSignal;
      }

      return {
        claimed: false,
        claimedAt: '',
        backdropCode: '',
      };
    }
  );

  static getFreeTrial = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (org && org.freeTrial) {
        return org.freeTrial;
      }

      return {
        claimed: false,
        started: false,
        claimedAt: '',
        backdropCode: '',
      };
    }
  );

  static isOrgBrandingConfigured = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (!org) {
        return false;
      }

      return org.logo_url && org.logo_url.length > 0;
    }
  );

  static isProfilePictureConfigured = createSelector(
    AuthUserSelector.getUser,
    (user) => {
      if (!user) {
        return false;
      }

      return user.avatar && user.avatar.length > 0;
    }
  );

  static isOnboarded = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (!org) {
        return false;
      }

      return Boolean(org.onboarded);
    }
  );

  static isOutlookAddonInstalled = createSelector(
    AuthUserSelector.getOrganization,
    (org) => {
      if (!org) {
        return false;
      }

      return Boolean(org.outlookAddonInstalled);
    }
  );
}

// handlers
function handleSetUser(state, { payload }) {
  return { ...state, user: payload };
}

function handleSetAccessToken(state, { payload }) {
  return { ...state, accessToken: payload };
}

function handleRemoveUser(state) {
  return { ...state, user: null };
}

function handleUpdateUser(state, { payload }) {
  const currentUser = state.user;
  const { organization, ...updatedUser } = payload;

  if (currentUser?._id !== updatedUser?._id) {
    return state;
  }

  const mergedUser = { ...currentUser, ...updatedUser };
  const reloadRequired =
    updatedUser.role && currentUser.role !== updatedUser.role;
  return {
    ...state,
    user: mergedUser,
    reloadRequired,
    reloadReason: 'role-changed',
  };
}

function handleUpdateOrg(state, { payload: newOrg }) {
  const oldOrg = state.user.organization;
  if (oldOrg._id === newOrg._id) {
    // same org
    const mergedOrg = { ...oldOrg, ...newOrg };
    const mergedUser = { ...state.user, organization: mergedOrg };

    const isPrevOrgFree = !oldOrg.freeTrial || !oldOrg.freeTrial.claimed;
    const isNextOrgPro = newOrg.freeTrial && newOrg.freeTrial.claimed;

    const isPrevOrgPro = oldOrg.freeTrial && oldOrg.freeTrial.claimed;
    const isPrevOrgExpired =
      newOrg.freeTrial && newOrg.freeTrial.expiratedAt < Date.now();

    const reloadRequired =
      (isPrevOrgFree && isNextOrgPro) || (isPrevOrgPro && isPrevOrgExpired);
    return {
      ...state,
      // TODO: investigate if this messed up uploadConfig
      user: mergedUser,
      reloadRequired,
      reloadReason: 'plan-changed',
    };
  }

  return state;
}

// reducer
const reducer = handleActions(
  {
    [AuthUserAction.setUser]: handleSetUser,
    [AuthUserAction.setAccessToken]: handleSetAccessToken,
    [AuthUserAction.removeUser]: handleRemoveUser,

    [RealtimeAction.USER_REMOVED]: handleRemoveUser,
    [RealtimeAction.USER_UPDATED]: handleUpdateUser,
    [RealtimeAction.ORG_UPDATED]: handleUpdateOrg,
  },
  defaultState
);

export default reducer;
