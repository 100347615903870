import { createAction, handleActions } from 'redux-actions';
import { path } from 'lodash/fp';
import { createSelector } from 'reselect';
import { batch } from 'react-redux';

import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import last from 'lodash/last';
import sortBy from 'lodash/fp/sortBy';

import api from '../api/feathers';
import { RealtimeAction } from '../store/realtime';
import {
  findAndReplaceMedia,
  findAndRemoveMedia,
  findMediaAndRemoveTag,
  findMediaAndUpdateTag,
} from './util';

// NOTE: be careful of this
// might lead to circular dependency
import { AuthUserSelector } from './authUser';

const TAG_TOP_LEVEL_FOLDERS = 'TAG_TOP_LEVEL_FOLDERS';

const defaultState = {
  // showing or not
  opened: false,
  // view mode is how we render the library
  // - mine: filter by user's personal uploads
  // - folder: filter by parent folder (TEAM DRIVE)
  // - trash: show all removed media
  // - tag: filter by tag
  // - documents: show all document files
  // - presentations: show all presentation files
  viewMode: 'folder',
  // selectedMedia: contains current selection
  selectedMedia: [],
  // filtering media by keyword
  keyword: '',
  // media currently displaying
  media: [],
  // current working folder
  currentFolder: null,
  // current sub-folder within a drop
  currentSubfolder: null,
  // top-level folders to show on sidebar
  topLevelFolders: [],
  // current tag name
  currentTagName: null,
  // all tags to show on sidebar,
  allTags: [],
  // media in clipboard
  clipboard: [],
  // all integrations to show on sidebar
  allIntegrations: [],
  // integration items
  integrationItems: [],
  // integration page info
  integrationPageInfo: {},
  // integration fetch error
  fetchIntegrationError: null,
  // is fetching items?
  fetchingIntegrationItems: false,
  // current integration
  currentIntegrationId: null,
  // media fetch error
  fetchMediaError: null,
  // is fetching items?
  fetchingMediaItems: false,
};

// actions
export class LibraryViewAction {
  // open/close
  static open = createAction('@library/OPEN');
  static close = createAction('@library/CLOSE');

  // media selection
  static selectMedia = createAction('@library/SELECT_MEDIA');
  static selectAll = createAction('@library/SELECT_ALL_MEDIA');
  static deselectAll = createAction('@library/DESELECT_ALL_MEDIA');
  static addToSelection = createAction('@library/ADD_TO_SELECTION');
  static removeFromSelection = createAction('@library/REMOVE_FROM_SELECTION');

  // search
  static search = createAction('@library/SEARCH');

  // media
  static fetchMediaStarted = createAction('@library/FETCH_MEDIA_STARTED');
  static fetchMediaFailed = createAction('@library/FETCH_MEDIA_FAILED');
  static fetchMediaCompleted = createAction('@library/FETCH_MEDIA_COMPLETED');

  static fetchMedia =
    (query = {}, tag = 'default') =>
    async (dispatch) => {
      dispatch(LibraryViewAction.fetchMediaStarted({ query, tag }));
      try {
        const finalQuery = { ...query, $sort: { updatedAt: -1 } };
        const result = await api.service('media').find({ query: finalQuery });
        dispatch(LibraryViewAction.fetchMediaCompleted({ query, tag, result }));
      } catch (error) {
        dispatch(LibraryViewAction.fetchMediaFailed({ query, tag, error }));
      }
    };

  // view mode
  static switchViewMode = createAction('@@library/SWITCH_VIEW_MODE');

  // tags
  static fetchTagsStarted = createAction('@library/FETCH_TAGS_STARTED');
  static fetchTagsFailed = createAction('@library/FETCH_TAGS_FAILED');
  static fetchTagsCompleted = createAction('@library/FETCH_TAGS_COMPLETED');

  static fetchTags =
    (query = {}, tag = 'default') =>
    async (dispatch) => {
      dispatch(LibraryViewAction.fetchTagsStarted({ query, tag }));
      try {
        const finalQuery = { ...query, $sort: { createdAt: 1 } };
        const result = await api.service('tags').find({ query: finalQuery });
        dispatch(LibraryViewAction.fetchTagsCompleted({ query, tag, result }));
      } catch (error) {
        dispatch(LibraryViewAction.fetchTagsFailed({ query, tag, error }));
      }
    };

  // integrations
  static fetchIntegrationsStarted = createAction(
    '@library/FETCH_INTEGRATIONS_STARTED'
  );
  static fetchIntegrationsFailed = createAction(
    '@library/FETCH_INTEGRATIONS_FAILED'
  );
  static fetchIntegrationsCompleted = createAction(
    '@library/FETCH_INTEGRATIONS_COMPLETED'
  );

  static fetchIntegrations =
    (query = {}, tag = 'default') =>
    async (dispatch) => {
      dispatch(LibraryViewAction.fetchIntegrationsStarted({ query, tag }));
      try {
        const finalQuery = { ...query, $sort: { createdAt: 1 } };
        const result = await api
          .service('integrations')
          .find({ query: finalQuery });
        dispatch(
          LibraryViewAction.fetchIntegrationsCompleted({ query, tag, result })
        );
      } catch (error) {
        dispatch(
          LibraryViewAction.fetchIntegrationsFailed({ query, tag, error })
        );
      }
    };

  // integration items
  static fetchIntegrationItemsStarted = createAction(
    '@library/FETCH_INTEGRATION_ITEMS_STARTED'
  );
  static fetchIntegrationItemsFailed = createAction(
    '@library/FETCH_INTEGRATION_ITEMS_FAILED'
  );
  static fetchIntegrationItemsCompleted = createAction(
    '@library/FETCH_INTEGRATION_ITEMS_COMPLETED'
  );

  static fetchIntegrationItems =
    (integrationId, query = {}, tag = 'default') =>
    async (dispatch) => {
      dispatch(
        LibraryViewAction.fetchIntegrationItemsStarted({
          integrationId,
          query,
          tag,
        })
      );
      try {
        const finalQuery = { ...query, limit: 50, $sort: { createdAt: 1 } };
        const result = await api
          .service(`integrations/${integrationId}/items`)
          .find({ query: finalQuery });
        dispatch(
          LibraryViewAction.fetchIntegrationItemsCompleted({
            integrationId,
            query,
            tag,
            result,
          })
        );
      } catch (error) {
        dispatch(
          LibraryViewAction.fetchIntegrationItemsFailed({
            integrationId,
            query,
            tag,
            error,
          })
        );
      }
    };

  static init =
    ({ viewMode = 'folder', item = null }) =>
    (dispatch) => {
      batch(() => {
        if (viewMode === 'integration') {
          dispatch(LibraryViewAction.goToIntegration(item));
        } else if (viewMode === 'documents' || viewMode === 'presentations') {
          dispatch(LibraryViewAction.showAllFilesByType(viewMode));
        } else if (viewMode === 'tag') {
          dispatch(LibraryViewAction.goToTagName(item));
        }

        // fetch top level folders
        dispatch(
          LibraryViewAction.fetchMedia(
            {
              $limit: 10000,
              parent: null,
              type: 'directory',
            },
            TAG_TOP_LEVEL_FOLDERS
          )
        );
        dispatch(LibraryViewAction.fetchTags({ $limit: 10000 }));
        dispatch(LibraryViewAction.fetchIntegrations({ $limit: 10000 }));
      });
    };

  // folder
  static goToFolder = (media) => (dispatch) =>
    batch(() => {
      dispatch(
        LibraryViewAction.switchViewMode({ mode: 'folder', item: media })
      );
      dispatch(
        LibraryViewAction.fetchMedia({
          $limit: 10000,
          private: { $ne: true },
          parent: media,
        })
      );
    });

  static goToFolderWithId = (mediaId) => async (dispatch) => {
    if (!mediaId) {
      dispatch(LibraryViewAction.goToFolder(null));
      return;
    }
    const folder = await api
      .service('media')
      .get(mediaId, { query: { $includeAncestors: true } });
    dispatch(LibraryViewAction.goToFolder(folder));
  };

  // personal folders
  static goToPersonalFolder = (media) => (dispatch, getState) => {
    const state = getState();
    const userId = AuthUserSelector.getUserId(state);

    dispatch(LibraryViewAction.switchViewMode({ mode: 'mine', item: media }));
    dispatch(
      LibraryViewAction.fetchMedia({
        $limit: 10000,
        private: true,
        createdBy: userId,
        parent: media,
      })
    );
  };

  static goToPersonalFolderWithId = (mediaId) => async (dispatch) => {
    if (!mediaId) {
      dispatch(LibraryViewAction.goToPersonalFolder(null));
      return;
    }
    const folder = await api
      .service('media')
      .get(mediaId, { query: { $includeAncestors: true } });
    dispatch(LibraryViewAction.goToPersonalFolder(folder));
  };

  // tag
  static goToTag = (tag) => (dispatch) =>
    batch(() => {
      dispatch(
        LibraryViewAction.switchViewMode({ mode: 'tag', item: tag.name })
      );
      dispatch(LibraryViewAction.fetchMedia({ $limit: 10000, tags: tag.name }));
    });

  static goToTagName = (tagName) => (dispatch) =>
    batch(() => {
      dispatch(
        LibraryViewAction.switchViewMode({ mode: 'tag', item: tagName })
      );
      dispatch(LibraryViewAction.fetchMedia({ $limit: 10000, tags: tagName }));
    });

  // integration
  static goToIntegration = (integration) => (dispatch) =>
    batch(() => {
      dispatch(
        LibraryViewAction.switchViewMode({
          mode: 'integration',
          item: integration,
        })
      );

      const integrationId =
        typeof integration === 'object' ? integration._id : integration;

      dispatch(
        LibraryViewAction.fetchIntegrationItems(integrationId, {
          $limit: 10000,
        })
      );
    });

  // file type
  // type can be 'documents' or 'presentations'
  static showAllFilesByType = (type) => (dispatch) => {
    dispatch(LibraryViewAction.switchViewMode({ mode: type }));
    let mimeTypes = [];
    if (type === 'documents') {
      mimeTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
    }
    if (type === 'presentations') {
      mimeTypes = [
        'application/mspowerpoint',
        'application/powerpoint',
        'application/vnd.ms-powerpoint',
        'application/x-mspowerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
    }
    dispatch(
      LibraryViewAction.fetchMedia({
        $limit: 10000,
        type: 'file',
        fileType: { $in: mimeTypes },
      })
    );
  };

  static goToTrash = () => (dispatch) => {
    dispatch(LibraryViewAction.switchViewMode({ mode: 'trash' }));

    dispatch(
      LibraryViewAction.fetchMedia({
        $limit: 10000,
        removed: true,
      })
    );
  };
}

const sortByTitle = sortBy(['title']);
const sortByName = sortBy(['name']);

// selectors
export class LibraryViewSelector {
  // open or not
  static isOpened = path('libraryView.opened');

  // search kw
  static getKeyword = path('libraryView.keyword');

  // media
  static getAllMedia = path('libraryView.media');

  // current folder
  static getCurrentFolder = path('libraryView.currentFolder');

  // integrations
  static getAllIntegrations = path('libraryView.allIntegrations');

  // current integration id
  static getCurrentIntegrationId = path('libraryView.currentIntegrationId');

  // get current tag object
  static getCurrentIntegration = createSelector(
    LibraryViewSelector.getAllIntegrations,
    LibraryViewSelector.getCurrentIntegrationId,
    (allItems = [], currentId) => find(allItems, { _id: currentId })
  );

  // current sub-folder within a drop
  static getCurrentSubfolder = path('libraryView.currentSubfolder');

  // topLevelFolders
  static legacy_getTopLevelFolders = path('libraryView.topLevelFolders');

  // top level folder sorted
  static getTopLevelFolders = createSelector(
    LibraryViewSelector.legacy_getTopLevelFolders,
    sortByTitle
  );

  // viewMode
  static getViewMode = path('libraryView.viewMode');

  // selection
  static getSelectedMedia = path('libraryView.selectedMedia');

  // tags
  static legacy_getAllTags = path('libraryView.allTags');

  static getAllTags = createSelector(
    LibraryViewSelector.legacy_getAllTags,
    sortByName
  );

  // current tag
  static getCurrentTagName = path('libraryView.currentTagName');

  // get current tag object
  static getCurrentTag = createSelector(
    LibraryViewSelector.getAllTags,
    LibraryViewSelector.getCurrentTagName,
    (allTags = [], currentTagName) => find(allTags, { name: currentTagName })
  );

  // visible media
  static getVisibleMedia = createSelector(
    LibraryViewSelector.getAllMedia,
    LibraryViewSelector.getKeyword,
    LibraryViewSelector.getViewMode,
    LibraryViewSelector.getCurrentFolder,
    LibraryViewSelector.getCurrentTagName,
    (allMedia = [], keyword, viewMode, currentFolder, currentTagName) => {
      // by default, do not filter anything
      let mainFilter = () => true;

      if (viewMode === 'folder') {
        // viewing folder, we want to filter by item's parent folder
        if (currentFolder) {
          mainFilter = (media) =>
            media.parent &&
            (media.parent === currentFolder._id ||
              media.parent._id === currentFolder._id);
        } else {
          mainFilter = (media) => !media.parent;
        }
      } else if (viewMode === 'mine') {
        // viewing personal folder, we want to filter by item's parent folder
        // and private flag
        if (currentFolder) {
          mainFilter = (media) =>
            media.parent &&
            (media.parent === currentFolder._id ||
              media.parent._id === currentFolder._id) &&
            Boolean(media.private);
        } else {
          mainFilter = (media) => !media.parent && Boolean(media.private);
        }
      } else if (viewMode === 'tag') {
        // viewing tag, filter by tag
        mainFilter = (media) =>
          media.tags && media.tags.indexOf(currentTagName) > -1;
      }

      const trashFilter =
        viewMode === 'trash'
          ? (media) => media.removed
          : (media) => !media.removed;

      const keywordFilter = (media) => {
        const title = media.title.toLowerCase();
        const q = keyword.toLowerCase();
        return title.indexOf(q) > -1;
      };

      return sortByTitle(
        allMedia.filter(mainFilter).filter(trashFilter).filter(keywordFilter)
      );
    }
  );

  // visible files
  static getVisibleFiles = createSelector(
    LibraryViewSelector.getVisibleMedia,
    (media) =>
      filter(
        media,
        (m) => m.type === 'file' || m.type === 'integration' || m.type === 'url'
      )
  );

  // visible folders
  static getVisibleFolders = createSelector(
    LibraryViewSelector.getVisibleMedia,
    (media) => filter(media, { type: 'directory' })
  );

  // visible integration items
  static getAllIntegrationItems = path('libraryView.integrationItems');

  // integration fetch error
  static getIntegrationFetchError = path('libraryView.fetchIntegrationError');

  // is fetching integrations items?
  static isFetchingIntegrationItems = path(
    'libraryView.fetchingIntegrationItems'
  );

  // clipboard
  static getClipboard = path('libraryView.clipboard');

  // media fetch error
  static getMediaFetchError = path('libraryView.fetchMediaError');
}

// handlers
// -------------

// media
function handleAddMedia(state, { payload }) {
  const oldRecords = state.media || [];

  // newly created media, set loading thumbnail
  let record = payload;
  if (record.type === 'file') {
    record.updatingThumbnail = true;
  }

  const nextRecords = [record, ...oldRecords];
  let nextTopLevelFolders = state.topLevelFolders;
  if (!record.parent && record.type === 'directory' && !record.private) {
    // adding to top level
    nextTopLevelFolders = [...state.topLevelFolders, record];
  }
  return { ...state, media: nextRecords, topLevelFolders: nextTopLevelFolders };
}

function handleUpdateMedia(state, { payload }) {
  // check if thumbnail is ready
  const record = payload;
  if (record.updatingThumbnail && record.thumbnailUrl) {
    record.updatingThumbnail = false;
  }

  // visible media
  const foundInMedia = find(state.media, { _id: record._id });
  const nextMedia = foundInMedia
    ? findAndReplaceMedia(state.media, record)
    : [...state.media, record];

  // top folders
  const isTopFolder =
    !record.parent && record.type === 'directory' && !record.private;
  let nextTopLevelFolders = state.topLevelFolders;
  const foundInTopLevel = find(nextTopLevelFolders, { _id: record._id });
  if (foundInTopLevel) {
    if (isTopFolder) {
      nextTopLevelFolders = findAndReplaceMedia(nextTopLevelFolders, record);
    } else {
      nextTopLevelFolders = reject(nextTopLevelFolders, { _id: record._id });
    }
  } else if (isTopFolder) {
    nextTopLevelFolders = [...nextTopLevelFolders, record];
  }

  // clipboard
  const nextClipboard = findAndReplaceMedia(state.clipboard, record);

  // selectedMedia
  const nextSelectedMedia = findAndReplaceMedia(state.selectedMedia, record);

  // currentFolder
  let nextCurrentFolder = state.currentFolder;
  if (state.currentFolder && state.currentFolder._id === record._id) {
    nextCurrentFolder = record;
  }

  return {
    ...state,
    media: nextMedia,
    topLevelFolders: nextTopLevelFolders,
    clipboard: nextClipboard,
    selectedMedia: nextSelectedMedia,
    currentFolder: nextCurrentFolder,
  };
}

function handleRemoveMedia(state, { payload }) {
  const record = payload;

  // visible media
  const nextMedia = findAndRemoveMedia(state.media, record);

  // selectedMedia
  const nextSelectedMedia = findAndRemoveMedia(state.selectedMedia, record);

  // top level folder
  const nextTopLevelFolders = findAndRemoveMedia(state.topLevelFolders, record);

  // clipboard
  const nextClipboard = findAndRemoveMedia(state.clipboard, record);

  return {
    ...state,
    media: nextMedia,
    topLevelFolders: nextTopLevelFolders,
    selectedMedia: nextSelectedMedia,
    clipboard: nextClipboard,
  };
}

function handleSearch(state, { payload }) {
  return { ...state, keyword: payload };
}

function handleFetchMediaStarted(state, { payload }) {
  const { query } = payload;
  return {
    ...state,
    fetchMediaError: null,
    fetchingMediaItems: true,
    media: [],
  };
}

function handleFetchMediaCompleted(state, { payload }) {
  const { query, tag, result } = payload;
  const { data, ...metadata } = result;

  let media = state.media;
  let topLevelFolders = state.topLevelFolders;

  if (tag === TAG_TOP_LEVEL_FOLDERS) {
    topLevelFolders = data.filter((m) => m.type === 'directory' && !m.private);
  } else {
    media = data.map((node) => ({
      ...node,
      parent: query.parent,
    }));
  }

  return { ...state, media, topLevelFolders };
}

function handleFetchMediaFailed(state, { payload }) {
  const { error } = payload;
  return {
    ...state,
    fetchMediaError: error,
    fetchingMediaItems: false,
  };
}

// SELECTIONS

function getItemsCollection(state) {
  const { viewMode } = state;
  const selector =
    viewMode === 'integration'
      ? LibraryViewSelector.getAllIntegrationItems
      : LibraryViewSelector.getVisibleFiles;
  return selector({
    libraryView: state,
  });
}

function getRangeSelection(collection, fromIndex, toIndex) {
  const selection = [];

  let i = fromIndex;
  const forward = fromIndex <= toIndex;
  while ((i - fromIndex) * (i - toIndex) <= 0) {
    const media = collection[i];
    if (
      media.type === 'file' ||
      media.type === 'pending_integration' ||
      media.type === 'integration' ||
      media.type === 'url'
    ) {
      selection.push(media);
    }

    i = forward ? i + 1 : i - 1;
  }

  return selection;
}

function handleSelectMedia(state, { payload }) {
  return { ...state, selectedMedia: [payload] };
}

function handleDeselectAllMedia(state) {
  if (state.selectedMedia && state.selectedMedia.length) {
    return { ...state, selectedMedia: [] };
  }

  return state;
}

function handleSelectAllMedia(state) {
  const visibleFiles = getItemsCollection(state);
  return { ...state, selectedMedia: visibleFiles };
}

function handleAddToSelection(state, { payload }) {
  const selectedMedia = [...state.selectedMedia, payload];
  return { ...state, selectedMedia };
}

function handleExtendSelectionLeft(state) {
  if (isEmpty(state.selectedMedia)) {
    return state;
  }
  const visibleFiles = getItemsCollection(state);
  const firstSelectedMedia = first(state.selectedMedia);
  const lastSelectedMedia = last(state.selectedMedia);

  const lastSelectionIndex = findIndex(visibleFiles, {
    _id: lastSelectedMedia._id,
  });
  if (lastSelectionIndex === 0) {
    return state;
  }

  const fromIndex = findIndex(visibleFiles, { _id: firstSelectedMedia._id });
  const toIndex = lastSelectionIndex - 1;

  const selectedMedia = getRangeSelection(visibleFiles, fromIndex, toIndex);
  return { ...state, selectedMedia };
}

function handleExtendSelectionRight(state) {
  if (isEmpty(state.selectedMedia)) {
    return state;
  }
  const visibleFiles = getItemsCollection(state);
  const firstSelectedMedia = first(state.selectedMedia);
  const lastSelectedMedia = last(state.selectedMedia);

  const lastSelectionIndex = findIndex(visibleFiles, {
    _id: lastSelectedMedia._id,
  });
  if (lastSelectionIndex >= visibleFiles.length - 1) {
    return state;
  }

  const fromIndex = findIndex(visibleFiles, { _id: firstSelectedMedia._id });
  const toIndex = lastSelectionIndex + 1;

  const selectedMedia = getRangeSelection(visibleFiles, fromIndex, toIndex);
  return { ...state, selectedMedia };
}

function handleSelectLeft(state) {
  if (isEmpty(state.selectedMedia)) {
    return state;
  }
  const visibleFiles = getItemsCollection(state);
  const lastSelectedMedia = last(state.selectedMedia);
  const lastSelectionIndex = findIndex(visibleFiles, {
    _id: lastSelectedMedia._id,
  });
  if (lastSelectionIndex === 0) {
    return state;
  }
  const selectedMedia = [visibleFiles[lastSelectionIndex - 1]];
  return { ...state, selectedMedia };
}

function handleSelectRight(state) {
  if (isEmpty(state.selectedMedia)) {
    return state;
  }
  const visibleFiles = getItemsCollection(state);
  const lastSelectedMedia = last(state.selectedMedia);
  const lastSelectionIndex = findIndex(visibleFiles, {
    _id: lastSelectedMedia._id,
  });
  if (lastSelectionIndex >= visibleFiles.length - 1) {
    return state;
  }
  const selectedMedia = [visibleFiles[lastSelectionIndex + 1]];
  return { ...state, selectedMedia };
}

function handleRemoveFromSelection(state, { payload }) {
  const selectedMedia = reject(state.selectedMedia, { _id: payload._id });
  return { ...state, selectedMedia };
}

function handleSelectTo(state, { payload }) {
  const visibleFiles = getItemsCollection(state);
  let selectedMedia = [];
  if (!state.selectedMedia || !state.selectedMedia.length) {
    // if there is no media selected, select current one
    selectedMedia = [payload];
  } else {
    // range select, from first selected media to current one
    const firstMedia = first(state.selectedMedia);
    const fromIndex = findIndex(visibleFiles, { _id: firstMedia._id });
    const toIndex = findIndex(visibleFiles, { _id: payload._id });

    selectedMedia = getRangeSelection(visibleFiles, fromIndex, toIndex);
  }
  return { ...state, selectedMedia };
}

function handleSwitchViewMode(state, { payload }) {
  const { mode, item = null } = payload;
  let currentFolder = state.currentFolder;
  let currentTagName = state.currentTagName;
  let currentIntegrationId = state.currentIntegrationId;
  if (mode === 'tag') {
    currentTagName = item;
  }
  if (mode === 'folder' || mode === 'mine') {
    currentFolder = item;
  }
  if (mode === 'integration') {
    currentIntegrationId = typeof item === 'object' ? item._id : item;
  }
  return {
    ...state,
    viewMode: mode,
    currentTagName,
    currentFolder,
    currentIntegrationId,
  };
}

function handleFetchTagsStarted(state) {
  return { ...state, allTags: [] };
}

function handleFetchTagsCompleted(state, { payload }) {
  const { query, tag, result } = payload;
  return { ...state, allTags: result.data };
}

function handleFetchIntegrationsStarted(state) {
  return { ...state, allIntegrations: [] };
}

function handleFetchIntegrationsCompleted(state, { payload }) {
  const { query, tag, result } = payload;
  return { ...state, allIntegrations: result.data };
}

function handleFetchIntegrationItemsStarted(state) {
  return { ...state, integrationItems: [], fetchingIntegrationItems: true };
}

function handleFetchIntegrationItemsCompleted(state, { payload }) {
  const { result } = payload;
  return {
    ...state,
    integrationItems: result.data,
    fetchIntegrationError: null,
    fetchingIntegrationItems: false,
  };
}

function handleFetchIntegrationItemsFailed(state, { payload }) {
  const { error } = payload;
  return {
    ...state,
    fetchIntegrationError: error,
    fetchingIntegrationItems: false,
  };
}

function handleAddIntegration(state, { payload }) {
  const oldRecords = state.allIntegrations || [];
  const nextRecords = [...oldRecords, payload];
  return { ...state, allIntegrations: nextRecords };
}

function handleRemoveIntegration(state, { payload }) {
  const integration = payload;

  const oldRecords = state.allIntegrations || [];
  const nextRecords = oldRecords.filter((t) => t._id !== integration._id);

  const isSelectedIntegrationRemoved =
    state.viewMode === 'integration' &&
    state.currentIntegrationId === integration._id;

  return {
    ...state,
    allIntegrations: nextRecords,
  };
}

function handleUpdateIntegration(state, { payload }) {
  const integration = payload;
  const oldRecords = state.allIntegrations || [];

  const nextRecords = oldRecords.map((record) => {
    if (record._id === integration._id) {
      return payload;
    }

    return record;
  });

  return { ...state, allIntegrations: nextRecords };
}

function handleAddTag(state, { payload }) {
  const oldRecords = state.allTags || [];
  const nextRecords = [...oldRecords, payload];
  return { ...state, allTags: nextRecords };
}

function handleRemoveTag(state, { payload }) {
  const tag = payload;

  const oldRecords = state.allTags || [];
  const nextRecords = oldRecords.filter((t) => t._id !== tag._id);

  const isSelectedTagRemoved =
    state.viewMode === 'tag' &&
    state.currentTagName &&
    tag.name === state.currentTagName;

  let nextMedia = state.media;

  if (isSelectedTagRemoved) {
    nextMedia = [];
  } else {
    // update all media with this tag removed
    nextMedia = findMediaAndRemoveTag(state.media, tag.name);
  }

  // selectedMedia
  const nextSelectedMedia = findMediaAndRemoveTag(
    state.selectedMedia,
    tag.name
  );

  // clipboard
  const nextClipboard = findMediaAndRemoveTag(state.clipboard, tag.name);

  return {
    ...state,
    allTags: nextRecords,
    media: nextMedia,
    selectedMedia: nextSelectedMedia,
    clipboard: nextClipboard,
  };
}

function handleUpdateTag(state, { payload }) {
  const newTag = payload;

  const oldRecords = state.allTags || [];
  const tagIndex = findIndex(oldRecords, { _id: newTag._id });
  if (tagIndex === -1) {
    return state;
  }

  // tag list
  const oldTag = oldRecords[tagIndex];
  const nextRecords = [...oldRecords]; // shallow clone
  nextRecords[tagIndex] = newTag;

  // visible media
  const nextMedia = findMediaAndUpdateTag(
    state.media,
    oldTag.name,
    newTag.name
  );

  // selectedMedia
  const nextSelectedMedia = findMediaAndUpdateTag(
    state.selectedMedia,
    oldTag.name,
    newTag.name
  );

  // clipboard
  const nextClipboard = findMediaAndUpdateTag(
    state.clipboard,
    oldTag.name,
    newTag.name
  );

  // currentTagName
  let nextCurrentTag = state.currentTagName;
  if (state.currentTagName && state.currentTagName === oldTag.name) {
    nextCurrentTag = newTag.name;
  }

  return {
    ...state,
    allTags: nextRecords,
    media: nextMedia,
    selectedMedia: nextSelectedMedia,
    clipboard: nextClipboard,
    currentTagName: nextCurrentTag,
  };
}

function handleOpen(state) {
  return {
    ...state,
    opened: true,
  };
}

function handleClose(state) {
  return defaultState;
}

// reducer
const reducer = handleActions(
  {
    [LibraryViewAction.open]: handleOpen,
    [LibraryViewAction.close]: handleClose,

    [LibraryViewAction.search]: handleSearch,
    [LibraryViewAction.fetchMediaStarted]: handleFetchMediaStarted,
    [LibraryViewAction.fetchMediaCompleted]: handleFetchMediaCompleted,
    [LibraryViewAction.fetchMediaFailed]: handleFetchMediaFailed,

    [RealtimeAction.MEDIA_CREATED]: handleAddMedia,
    [RealtimeAction.MEDIA_REMOVED]: handleRemoveMedia,
    [RealtimeAction.MEDIA_UPDATED]: handleUpdateMedia,

    [RealtimeAction.TAG_CREATED]: handleAddTag,
    [RealtimeAction.TAG_REMOVED]: handleRemoveTag,
    [RealtimeAction.TAG_UPDATED]: handleUpdateTag,

    [LibraryViewAction.selectMedia]: handleSelectMedia,
    [LibraryViewAction.deselectAll]: handleDeselectAllMedia,
    [LibraryViewAction.selectAll]: handleSelectAllMedia,
    [LibraryViewAction.addToSelection]: handleAddToSelection,
    [LibraryViewAction.removeFromSelection]: handleRemoveFromSelection,

    [LibraryViewAction.switchViewMode]: handleSwitchViewMode,

    [LibraryViewAction.fetchTagsStarted]: handleFetchTagsStarted,
    [LibraryViewAction.fetchTagsCompleted]: handleFetchTagsCompleted,

    [LibraryViewAction.fetchIntegrationsStarted]:
      handleFetchIntegrationsStarted,
    [LibraryViewAction.fetchIntegrationsCompleted]:
      handleFetchIntegrationsCompleted,

    [LibraryViewAction.fetchIntegrationItemsStarted]:
      handleFetchIntegrationItemsStarted,
    [LibraryViewAction.fetchIntegrationItemsCompleted]:
      handleFetchIntegrationItemsCompleted,
    [LibraryViewAction.fetchIntegrationItemsFailed]:
      handleFetchIntegrationItemsFailed,
  },
  defaultState
);

export default reducer;
