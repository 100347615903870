import getConfig from 'next/config';
const config = getConfig();

export const API_ROOT = config.publicRuntimeConfig.API_ROOT;
export const END_USER_URL = config.publicRuntimeConfig.END_USER_URL;
export const HELP_LINK = config.publicRuntimeConfig.HELP_LINK;
export const ADMIN_CP_URL = config.publicRuntimeConfig.ADMIN_CP_URL;
export const APP_ENVIRONMENT = config.publicRuntimeConfig.APP_ENVIRONMENT;
export const PDFTRON_SERVER = config.publicRuntimeConfig.PDFTRON_SERVER;
export const APP_VERSION = config.publicRuntimeConfig.APP_VERSION;

export default config.publicRuntimeConfig;
