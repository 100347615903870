import { createAction, handleActions } from 'redux-actions';
import { path, map, flow, filter } from 'lodash/fp';
import api from '../api';
import { findBackdropsByRecipient } from '../api/backdrop';
import { createSelector } from 'reselect';
import { RealtimeAction } from '../store/realtime';
import { WizardAction } from './wizard';

import { nanoid } from 'nanoid';
import emailToName from 'email-to-name';

import get from 'lodash/get';
import sortBy from 'lodash/fp/sortBy';
import isBefore from 'date-fns/isBefore';
import { getStorate } from '../components/universalStorage';

import { findAndReplaceMediaInSpace, findAndReplacePostInSpace } from './util';

const defaultState = {
  // mode: current UI mode (compose | reply)
  mode: 'compose',

  // selectedItem: contains selected backdrop
  selectedItem: null,

  // invites: list of fetched backdrop for current recipient
  invites: [],

  // playbooks
  playbooks: [],

  // team members
  members: [],

  // templates
  templates: [],

  // draftSpace: newly created space
  draftSpace: null,

  // api call
  requestId: '',
  loading: true,
  failed: false,
  completed: false,
};

const notExpiredYet = (backdrop) =>
  !backdrop.space.expiration ||
  isBefore(new Date(), new Date(backdrop.space.expiration));
const notArchived = (backdrop) => backdrop.space.status !== 'archived';
const hasSpace = (backdrop) => Boolean(backdrop.space);

// selectors
const sortByName = sortBy(['name']);
export class SpaceViewSelector {
  static getCurrentMode = path('spaceView.mode');

  static getSelectedBackdrop = path('spaceView.selectedItem');
  static getDraftSpace = path('spaceView.draftSpace');
  static getAllBackdrops = path('spaceView.invites');

  static isLoading = path('spaceView.loading');
  static isFailed = path('spaceView.failed');
  static isCompleted = path('spaceView.completed');

  static getFetchStatus = createSelector(
    SpaceViewSelector.isLoading,
    SpaceViewSelector.isFailed,
    SpaceViewSelector.isCompleted,
    (loading, failed, completed) => ({ loading, failed, completed })
  );

  static getActiveBackdrops = createSelector(
    SpaceViewSelector.getAllBackdrops,
    (backdrops) => {
      return backdrops
        .filter(hasSpace)
        .filter(notArchived)
        .filter(notExpiredYet);
    }
  );

  static hasActiveBackdrops = createSelector(
    SpaceViewSelector.getActiveBackdrops,
    (backdrops) => backdrops.length > 0
  );

  // playbooks
  static getAllPlaybooks = path('spaceView.playbooks');

  static getSortedPlaybooks = createSelector(
    SpaceViewSelector.getAllPlaybooks,
    sortByName
  );

  // team members
  static getTeamMembers = path('spaceView.members');

  // templates
  static getTemplates = path('spaceView.templates');
}

// actions
export class SpaceViewAction {
  // mode
  static setCurrentMode = createAction('SET_MODE');

  // active invite
  static selectInvite = createAction('SELECT_INVITE');
  static deselecInvite = createAction('DESELECT_INVITE');

  // draft space
  static setDraftSpace = createAction('SET_DRAFT_SPACE');

  // playbooks
  static setPlaybooks = createAction('SET_PLAYBOOKS');

  // members
  static setTeamMembers = createAction('SET_TEAM_MEMBERS');

  // templates
  static setTemplates = createAction('SET_TEMPLATES');

  // add/remove invite
  static removeInvite = createAction('REMOVE_INVITE');
  static addInvite = createAction('ADD_INVITE');

  // backdrops
  static fetchInvitesStarted = createAction('FETCH_INVITES_STARTED');
  static fetchInvitesFailed = createAction('FETCH_INVITES_FAILED');
  static fetchInvitesCompleted = createAction('FETCH_INVITES_COMPLETED');

  static fetchBackdrops =
    (
      recipient,
      orgId,
      options = {
        mode: 'compose',
        defaultSubject: 'Sales Package',
        draftTemplate: false,
      }
    ) =>
    async (dispatch, getState) => {
      const {
        mode = 'compose',
        backdropCode,
        defaultSubject = 'Sales Package',
      } = options;
      const requestId = nanoid();
      dispatch(
        SpaceViewAction.fetchInvitesStarted({ requestId, recipient, orgId })
      );
      try {
        let [contactIds, backdrops] = await findBackdropsByRecipient(
          recipient,
          orgId
        );

        if (!contactIds) {
          const contact = await api.service('contacts').create({
            email: recipient,
            name: emailToName.process(recipient),
          });
        }

        if (backdrops.length === 0 && !getState().spaceView.draftSpace) {
          const user = getState().authUser.user;
          const data = {
            title: defaultSubject,
            posts: [],
          };
          if (user.defaultTemplateId) {
            data.draftTemplate = user.defaultTemplateId;
          }
          // need to create a draft space
          const space = await api.service('spaces').create(data);

          const storage = getStorate();
          storage.setItem('draftSpaceId', space._id);
          dispatch(SpaceViewAction.setDraftSpace(space));
        }

        console.log('backdrops', backdrops);
        console.log('backdropCode', backdropCode);

        const selectedBackdrop = backdrops.find(
          (backdrop) => backdrop.code === backdropCode
        );

        dispatch(
          SpaceViewAction.fetchInvitesCompleted({
            requestId,
            contactIds,
            backdrops,
            mode,
            selectedBackdrop,
          })
        );
      } catch (error) {
        console.error(error);
        dispatch(
          SpaceViewAction.fetchInvitesFailed({
            requestId,
            error: error.message,
          })
        );
      }
    };

  // playbooks
  static fetchTagsStarted = createAction('FETCH_TAGS_STARTED');
  static fetchTagsFailed = createAction('FETCH_TAGS_FAILED');
  static fetchTagsCompleted = createAction('FETCH_TAGS_COMPLETED');

  static fetchPlaybooks = () => async (dispatch) => {
    dispatch(SpaceViewAction.fetchTagsStarted());
    try {
      const finalQuery = { $limit: 10000, $sort: { createdAt: 1 } };
      const result = await api.service('tags').find({ query: finalQuery });
      dispatch(SpaceViewAction.fetchTagsCompleted(result));
    } catch (error) {
      dispatch(SpaceViewAction.fetchTagsFailed(error));
    }
  };
}

// const deletedContact = {
//   _id: 'deleted',
//   name: '[Deleted]',
// };
// const filterInvite = (invite) => invite.space && invite.space._id;
// const mapInvite = (invite) => ({
//   _id: invite._id,
//   spaceId: invite.space._id,
//   title: invite.space.title,
//   introText: invite.space.introText,
//   recipientName: invite.contact ? invite.contact.name : '[Deleted]',
//   recipientEmail: invite.contact?.email,
//   recipientCompany: invite.contact?.company?.name,
//   createdAt: invite.createdAt,
//   viewed: invite.viewed,
//   status: invite.space.status,
//   expired: isAfter(new Date(), new Date(invite.space.expiration)),
//   expiration: invite.space.expiration,
//   post: path('space.posts[0]', invite),
//   posts: path('space.posts', invite),
//   media: path('space.posts[0].media[0]', invite),
//   docs: path('space.posts[0].media', invite),
//   createdFromTag: path('space.createdFromTag', invite),
//   recipientsCanUpload: Boolean(invite.recipientsCanUpload),
//   type: 'invite',
//   // more data for invite
//   isContactDeleted: !invite.contact || invite.contact.isDeleted,
//   contact: invite.contact ? invite.contact : deletedContact,
//   code: invite.code,
// });
// const normalizeInvites = flow(filter(filterInvite), map(mapInvite));

// handlers

function handleSelectInvite(state, { payload }) {
  // next invites
  let nextInvites = state.invites;
  const inviteIndex = state.invites.findIndex(
    (invite) => invite._id === payload._id
  );
  if (inviteIndex === -1) {
    nextInvites = [...nextInvites, payload];
  }

  return { ...state, selectedItem: payload, invites: nextInvites };
}

function handleDeselectItem(state) {
  return { ...state, selectedItem: null };
}

function handleFetchStarted(state, { payload }) {
  const { requestId } = payload;
  return {
    ...state,
    requestId,
    loading: true,
    failed: false,
    completed: false,
  };
}

function handleFetchFailed(state, { payload }) {
  const { requestId } = payload;
  if (state.requestId === requestId) {
    return { ...state, loading: false, failed: true, completed: false };
  }
  return state;
}

function handleFetchInvitesCompleted(state, { payload }) {
  const { requestId, backdrops, selectedBackdrop, mode = 'compose' } = payload;
  if (state.requestId === requestId) {
    return {
      ...state,
      mode,
      invites: backdrops,
      selectedItem: selectedBackdrop,
      loading: false,
      failed: false,
      completed: true,
    };
  }

  return state;
}

function handleAddInvite(state, { payload }) {
  const nextInvites = [...state.invites, payload];
  return { ...state, invites: nextInvites };
}

function handleRemoveInvite(state, { payload }) {
  const oldItems = state.invites || [];
  const nextInvites = oldItems.filter((c) => c._id !== payload);
  const oldSelectedItem = state.selectedItem;
  const nextSelectedItem =
    oldSelectedItem && oldSelectedItem._id !== payload ? oldSelectedItem : null;
  return {
    ...state,
    invites: nextInvites,
    selectedItem: nextSelectedItem,
  };
}

function handleSpaceUpdated(state, { payload }) {
  // selectedItem
  let nextSelectedItem = state.selectedItem;

  // invites
  let nextInvites = state.invites;
  const inviteIndex = state.invites.findIndex(
    (invite) => invite.space._id === payload._id
  );
  if (inviteIndex > -1) {
    nextInvites = [...state.invites]; // clone
    const invite = { ...state.invites[inviteIndex] };
    invite.space = payload;
    nextInvites[inviteIndex] = invite;

    if (state.selectedItem && state.selectedItem._id === invite._id) {
      nextSelectedItem = invite;
    }
  }

  // draft space
  let nextDraftSpace = state.draftSpace;
  if (state.draftSpace && state.draftSpace._id === payload._id) {
    nextDraftSpace = payload;
  }

  return {
    ...state,
    invites: nextInvites,
    selectedItem: nextSelectedItem,
    draftSpace: nextDraftSpace,
  };
}

function handleInviteUpdated(state, { payload }) {
  // selectedItem
  let nextSelectedItem = state.selectedItem;

  // invites
  let nextInvites = state.invites;
  const inviteIndex = state.invites.findIndex(
    (invite) => invite._id === payload._id
  );
  if (inviteIndex > -1) {
    nextInvites = [...state.invites]; // clone
    // const oldInvite = nextInvites[inviteIndex];
    // nextInvites[inviteIndex] = {
    //   ...oldInvite,
    //   visibility: payload.visibility,
    //   custom_orgName: payload.custom_orgName,
    //   custom_logo: payload.custom_logo,
    //   custom_sidebar: payload.custom_sidebar,
    //   recipientsCanUpload: payload.recipientsCanUpload,
    //   disableRecipientVerification: payload.disableRecipientVerification,
    // };
    nextInvites[inviteIndex] = payload;

    if (state.selectedItem && state.selectedItem._id === payload._id) {
      // nextSelectedItem = {
      //   ...state.selectedItem,
      //   recipientsCanUpload: payload.recipientsCanUpload,
      //   disableRecipientVerification: payload.disableRecipientVerification,
      // };
      nextSelectedItem = payload;
    }
  }

  return {
    ...state,
    invites: nextInvites,
    selectedItem: nextSelectedItem,
  };
}

function handleUpdateMedia(state, { payload }) {
  // selected item
  let nextSelectedItem = state.selectedItem;
  if (nextSelectedItem && nextSelectedItem.space) {
    const nextSpace = findAndReplaceMediaInSpace(
      nextSelectedItem.space,
      payload
    );
    nextSelectedItem.space = nextSpace;
  }

  // draftSpace
  let nextDraftSpace = state.draftSpace;
  if (nextDraftSpace) {
    nextDraftSpace = findAndReplaceMediaInSpace(nextDraftSpace, payload);
  }

  return {
    ...state,
    selectedItem: nextSelectedItem,
    draftSpace: nextDraftSpace,
  };
}

function handlePostUpdated(state, { payload }) {
  // selected item
  let nextSelectedItem = state.selectedItem;
  if (nextSelectedItem && nextSelectedItem.space) {
    const nextSpace = findAndReplacePostInSpace(
      nextSelectedItem.space,
      payload
    );
    nextSelectedItem.space = nextSpace;
  }

  // draftSpace
  let nextDraftSpace = state.draftSpace;
  if (nextDraftSpace) {
    nextDraftSpace = findAndReplacePostInSpace(nextDraftSpace, payload);
  }

  return {
    ...state,
    selectedItem: nextSelectedItem,
    draftSpace: nextDraftSpace,
  };
}

function handleGoBackToStepHome(state) {
  return { ...state, selectedItem: null };
}

function handleSetDraftSpace(state, { payload }) {
  return { ...state, draftSpace: payload };
}

function handleSetPlaybooks(state, { payload }) {
  return { ...state, playbooks: payload };
}

function handleSetTeamMembers(state, { payload }) {
  return { ...state, members: payload };
}

function handleSetTemplates(state, { payload }) {
  return { ...state, templates: payload };
}

function handleFetchTagsStarted(state) {
  return { ...state, playbooks: [] };
}

function handleFetchTagsCompleted(state, { payload }) {
  return { ...state, playbooks: payload.data };
}

function handleSetCurrentMode(state, { payload }) {
  return { ...state, mode: payload };
}

// reducer
const reducer = handleActions(
  {
    // mode
    [SpaceViewAction.setCurrentMode]: handleSetCurrentMode,

    [SpaceViewAction.selectInvite]: handleSelectInvite,
    [SpaceViewAction.deselecInvite]: handleDeselectItem,
    [SpaceViewAction.removeInvite]: handleRemoveInvite,
    [SpaceViewAction.addInvite]: handleAddInvite,
    [SpaceViewAction.fetchInvitesStarted]: handleFetchStarted,
    [SpaceViewAction.fetchInvitesFailed]: handleFetchFailed,
    [SpaceViewAction.fetchInvitesCompleted]: handleFetchInvitesCompleted,

    // draft
    [SpaceViewAction.setDraftSpace]: handleSetDraftSpace,

    // playbook
    [SpaceViewAction.setPlaybooks]: handleSetPlaybooks,

    // team members
    [SpaceViewAction.setTeamMembers]: handleSetTeamMembers,

    // templates
    [SpaceViewAction.setTemplates]: handleSetTemplates,

    // realtime
    [RealtimeAction.SPACE_UPDATED]: handleSpaceUpdated,
    [RealtimeAction.MEDIA_UPDATED]: handleUpdateMedia,
    [RealtimeAction.POST_UPDATED]: handlePostUpdated,
    [RealtimeAction.SPACE_INVITE_UPDATED]: handleInviteUpdated,

    [WizardAction.goToStepHome]: handleGoBackToStepHome,

    // playbooks
    [SpaceViewAction.fetchTagsStarted]: handleFetchTagsStarted,
    [SpaceViewAction.fetchTagsCompleted]: handleFetchTagsCompleted,
  },
  defaultState
);

export default reducer;
