import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { path } from 'lodash/fp';

const defaultState = {
  activeStep: 'home',
  subStep: '',
};

// actions
export class WizardAction {
  static goToStepHome = createAction('GO_TO_HOME');
  static goToStepBackdrop = createAction('GO_TO_BACKDROP');
  static goToStepDraftSpace = createAction('GO_TO_DRAFT_SPACE');
  static goToStepPermission = createAction('GO_TO_PERMISSION');
  static goToStepComplete = createAction('GO_TO_COMPLETE');

  static goBack = createAction('GO_BACK');

  static browserLibrary = createAction('BROWSE_LIBRARY');
  static exitLibrary = createAction('EXIT_LIBRARY');
}

// selectors
export class WizardSelector {
  static getActiveStep = path('wizard.activeStep');

  static getPreviousStep = createSelector(
    WizardSelector.getActiveStep,
    (activeStep) => {
      switch (activeStep) {
        case 'complete':
          return 'permission';

        case 'permission':
          return 'backdrop';

        case 'home':
          return null;

        default:
          return 'home';
      }
    }
  );

  static getSubStep = path('wizard.subStep');
  static isBrowsingLibrary = createSelector(
    WizardSelector.getSubStep,
    (substep) => substep === 'library'
  );
}

// handlers
function handleGoToHome(state) {
  return { ...state, activeStep: 'home' };
}

function handleGoToBackdrop(state) {
  return { ...state, activeStep: 'backdrop' };
}

function handleGoToPermission(state) {
  return { ...state, activeStep: 'permission' };
}

function handleFetchCompleted(state, { payload }) {
  const activeStep = payload.mode === 'reply' ? 'backdrop' : 'home';
  return { ...state, activeStep };
}

function handleGoToDraftSpace(state) {
  return { ...state, activeStep: 'draft' };
}

function handleGoToComplete(state) {
  return { ...state, activeStep: 'complete' };
}

function handleBrowserLibrary(state) {
  return { ...state, subStep: 'library' };
}

function handleExitLibrary(state) {
  return { ...state, subStep: '' };
}

function handleGoBack(state) {
  let activeStep = state.activeStep;
  if (activeStep === 'complete') {
    activeStep = 'permission';
  } else if (activeStep === 'permission') {
    activeStep = 'backdrop';
  } else {
    activeStep = 'home';
  }
  return { ...state, activeStep };
}

// reducer
const reducer = handleActions(
  {
    [WizardAction.goToStepHome]: handleGoToHome,
    [WizardAction.goToStepBackdrop]: handleGoToBackdrop,
    [WizardAction.goToStepPermission]: handleGoToPermission,
    [WizardAction.goToStepDraftSpace]: handleGoToDraftSpace,
    [WizardAction.goToStepComplete]: handleGoToComplete,
    [WizardAction.goBack]: handleGoBack,
    [WizardAction.browserLibrary]: handleBrowserLibrary,
    [WizardAction.exitLibrary]: handleExitLibrary,
    FETCH_INVITES_COMPLETED: handleFetchCompleted,
  },
  defaultState
);

export default reducer;
