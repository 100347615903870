import api from '../api';
import { batch } from 'react-redux';
import { Subject, OperatorFunction, Observable } from 'rxjs';
import { debounceTime, buffer, tap, map } from 'rxjs/operators';

// buffer and batch actions
function bufferDebounceTime(time = 0) {
  return (source) => {
    let bufferedValues = [];

    return source.pipe(
      tap((value) => bufferedValues.push(value)),
      debounceTime(time),
      map(() => bufferedValues),
      tap(() => (bufferedValues = []))
    );
  };
}

function createEventStream(store) {
  const event$ = new Subject();

  event$
    .asObservable()
    .pipe(bufferDebounceTime(100))
    .subscribe((actions) =>
      batch(() => {
        actions.forEach((action) => store.dispatch(action));
      })
    );

  return event$;
}

// actions
const EVENT_CREATED = '@@realtime/EVENT_CREATED';
const EVENT_UPDATED = '@@realtime/EVENT_UPDATED';

const CONTACT_CREATED = '@@realtime/CONTACT_CREATED';
const CONTACT_UPDATED = '@@realtime/CONTACT_UPDATED';
const CONTACT_REMOVED = '@@realtime/CONTACT_REMOVED';

const SPACE_CREATED = '@@realtime/SPACE_CREATED';
const SPACE_UPDATED = '@@realtime/SPACE_UPDATED';
const SPACE_REMOVED = '@@realtime/SPACE_REMOVED';

const SPACE_INVITE_CREATED = '@@realtime/SPACE_INVITE_CREATED';
const SPACE_INVITE_UPDATED = '@@realtime/SPACE_INVITE_UPDATED';
const SPACE_INVITE_REMOVED = '@@realtime/SPACE_INVITE_REMOVED';

const USER_CREATED = '@@realtime/USER_CREATED';
const USER_UPDATED = '@@realtime/USER_UPDATED';
const USER_REMOVED = '@@realtime/USER_REMOVED';

const ORG_CREATED = '@@realtime/ORG_CREATED';
const ORG_UPDATED = '@@realtime/ORG_UPDATED';
const ORG_REMOVED = '@@realtime/ORG_REMOVED';

const MEDIA_CREATED = '@@realtime/MEDIA_CREATED';
const MEDIA_UPDATED = '@@realtime/MEDIA_UPDATED';
const MEDIA_REMOVED = '@@realtime/MEDIA_REMOVED';

const POST_CREATED = '@@realtime/POST_CREATED';
const POST_UPDATED = '@@realtime/POST_UPDATED';
const POST_REMOVED = '@@realtime/POST_REMOVED';

const WIDGET_CREATED = '@@realtime/WIDGET_CREATED';
const WIDGET_UPDATED = '@@realtime/WIDGET_UPDATED';
const WIDGET_REMOVED = '@@realtime/WIDGET_REMOVED';

const TEMPLATE_CREATED = '@@realtime/TEMPLATE_CREATED';
const TEMPLATE_UPDATED = '@@realtime/TEMPLATE_UPDATED';
const TEMPLATE_REMOVED = '@@realtime/TEMPLATE_REMOVED';

const TAG_CREATED = '@@realtime/TAG_CREATED';
const TAG_UPDATED = '@@realtime/TAG_UPDATED';
const TAG_REMOVED = '@@realtime/TAG_REMOVED';

const INTEGRATION_CREATED = '@@realtime/INTEGRATION_CREATED';
const INTEGRATION_UPDATED = '@@realtime/INTEGRATION_UPDATED';
const INTEGRATION_REMOVED = '@@realtime/INTEGRATION_REMOVED';

const COMPANY_CREATED = '@@realtime/COMPANY_CREATED';
const COMPANY_UPDATED = '@@realtime/COMPANY_UPDATED';
const COMPANY_REMOVED = '@@realtime/COMPANY_REMOVED';

export const RealtimeAction = {
  // event
  EVENT_CREATED,
  EVENT_UPDATED,
  // contact
  CONTACT_CREATED,
  CONTACT_UPDATED,
  CONTACT_REMOVED,
  // space
  SPACE_CREATED,
  SPACE_UPDATED,
  SPACE_REMOVED,
  // space invite
  SPACE_INVITE_CREATED,
  SPACE_INVITE_UPDATED,
  SPACE_INVITE_REMOVED,
  // user
  USER_CREATED,
  USER_UPDATED,
  USER_REMOVED,
  // org
  ORG_CREATED,
  ORG_UPDATED,
  ORG_REMOVED,
  // media
  MEDIA_CREATED,
  MEDIA_UPDATED,
  MEDIA_REMOVED,
  // post
  POST_CREATED,
  POST_UPDATED,
  POST_REMOVED,
  // widget
  WIDGET_CREATED,
  WIDGET_UPDATED,
  WIDGET_REMOVED,
  // template
  TEMPLATE_CREATED,
  TEMPLATE_UPDATED,
  TEMPLATE_REMOVED,
  // tag
  TAG_CREATED,
  TAG_UPDATED,
  TAG_REMOVED,
  // integration
  INTEGRATION_CREATED,
  INTEGRATION_UPDATED,
  INTEGRATION_REMOVED,
  // integration
  COMPANY_CREATED,
  COMPANY_UPDATED,
  COMPANY_REMOVED,
};

// handlers
const setupRealtimeEvents = (event$) => {
  const handleEventCreated = async (event) => {
    const fullEvent = await api.service('events').get(event._id);
    event$.next({ type: EVENT_CREATED, payload: fullEvent });
  };

  const handleEventUpdated = (event) => {
    event$.next({ type: EVENT_UPDATED, payload: event });
  };

  api.service('events').on('created', handleEventCreated);
  api.service('events').on('patched', handleEventUpdated);
};

const setupRealtimeContacts = (event$) => {
  const handleContactCreated = async (contact) => {
    const fullContact = await api.service('contacts').get(contact._id);
    event$.next({ type: CONTACT_CREATED, payload: fullContact });
  };

  const handleContactUpdated = (contact) => {
    event$.next({ type: CONTACT_UPDATED, payload: contact });
  };

  const handleContactRemoved = (contact) => {
    console.log('contact removed', contact);
    event$.next({ type: CONTACT_REMOVED, payload: contact });
  };

  api.service('contacts').on('created', handleContactCreated);
  api.service('contacts').on('patched', handleContactUpdated);
  api.service('contacts').on('removed', handleContactRemoved);
};

const setupRealtimeSpaces = (event$) => {
  const handleCreated = async (space) => {
    const fullSpace = await api.service('spaces').get(space._id);
    event$.next({ type: SPACE_CREATED, payload: fullSpace });
  };

  const handleUpdated = (space) => {
    event$.next({ type: SPACE_UPDATED, payload: space });
  };

  const handleRemoved = (space) => {
    event$.next({ type: SPACE_REMOVED, payload: space });
  };

  api.service('spaces').on('created', handleCreated);
  api.service('spaces').on('patched', handleUpdated);
  api.service('spaces').on('removed', handleRemoved);
};

const setupRealtimeUsers = (event$) => {
  const handleCreated = async (user) => {
    // const fullSpace = await api.service('spaces').get(space._id);
    event$.next({ type: USER_CREATED, payload: user });
  };

  const handleUpdated = (user) => {
    event$.next({ type: USER_UPDATED, payload: user });
  };

  const handleRemoved = (user) => {
    event$.next({ type: USER_REMOVED, payload: user });
  };

  api.service('users').on('created', handleCreated);
  api.service('users').on('patched', handleUpdated);
  api.service('users').on('removed', handleRemoved);
};

const setupRealtimeOrgs = (event$) => {
  const handleCreated = async (organization) => {
    event$.next({ type: ORG_CREATED, payload: organization });
  };

  const handleUpdated = (organization) => {
    event$.next({ type: ORG_UPDATED, payload: organization });
  };

  const handleRemoved = (organization) => {
    event$.next({ type: ORG_REMOVED, payload: organization });
  };

  api.service('organizations').on('created', handleCreated);
  api.service('organizations').on('patched', handleUpdated);
  api.service('organizations').on('removed', handleRemoved);
};

const setupRealtimeMedia = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: MEDIA_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: MEDIA_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: MEDIA_REMOVED, payload: record });
  };

  api.service('media').on('created', handleCreated);
  api.service('media').on('patched', handleUpdated);
  api.service('media').on('removed', handleRemoved);
};

const setupRealtimePosts = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: POST_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: POST_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: POST_REMOVED, payload: record });
  };

  api.service('posts').on('created', handleCreated);
  api.service('posts').on('patched', handleUpdated);
  api.service('posts').on('removed', handleRemoved);

  api.service('spaces/:spaceId/posts').on('created', handleCreated);
  api.service('spaces/:spaceId/posts').on('patched', handleUpdated);
  api.service('spaces/:spaceId/posts').on('removed', handleRemoved);
};

const setupRealtimeWidgets = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: WIDGET_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: WIDGET_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: WIDGET_REMOVED, payload: record });
  };

  api.service('widgets').on('created', handleCreated);
  api.service('widgets').on('patched', handleUpdated);
  api.service('widgets').on('removed', handleRemoved);
};

const setupRealtimeTemplates = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: TEMPLATE_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: TEMPLATE_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: TEMPLATE_REMOVED, payload: record });
  };

  api.service('templates').on('created', handleCreated);
  api.service('templates').on('patched', handleUpdated);
  api.service('templates').on('removed', handleRemoved);
};

const setupRealtimeTags = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: TAG_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: TAG_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: TAG_REMOVED, payload: record });
  };

  api.service('tags').on('created', handleCreated);
  api.service('tags').on('patched', handleUpdated);
  api.service('tags').on('removed', handleRemoved);
};

const setupRealtimeIntegrations = (event$) => {
  const handleCreated = async (record) => {
    event$.next({ type: INTEGRATION_CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: INTEGRATION_UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: INTEGRATION_REMOVED, payload: record });
  };

  api.service('integrations').on('created', handleCreated);
  api.service('integrations').on('patched', handleUpdated);
  api.service('integrations').on('removed', handleRemoved);
};

const setupRealtime = (event$, service, eventTypes) => {
  const { CREATED, UPDATED, REMOVED } = eventTypes;
  const handleCreated = async (record) => {
    event$.next({ type: CREATED, payload: record });
  };

  const handleUpdated = (record) => {
    event$.next({ type: UPDATED, payload: record });
  };

  const handleRemoved = (record) => {
    event$.next({ type: REMOVED, payload: record });
  };

  api.service(service).on('created', handleCreated);
  api.service(service).on('patched', handleUpdated);
  api.service(service).on('removed', handleRemoved);
};

const realtimeStoreEnhancer =
  (createStore) => (reducer, preloadedState, enhancer) => {
    const store = createStore(reducer, preloadedState, enhancer);

    const event$ = createEventStream(store);

    setupRealtimeEvents(event$);
    setupRealtimeContacts(event$);
    setupRealtimeSpaces(event$);
    setupRealtimeUsers(event$);
    setupRealtimeOrgs(event$);
    setupRealtimeMedia(event$);
    setupRealtimePosts(event$);
    setupRealtimeWidgets(event$);
    setupRealtimeTemplates(event$);
    setupRealtimeTags(event$);
    setupRealtimeIntegrations(event$);
    setupRealtime(event$, 'companies', {
      CREATED: COMPANY_CREATED,
      UPDATED: COMPANY_UPDATED,
      REMOVED: COMPANY_REMOVED,
    });
    setupRealtime(event$, 'space-invites', {
      CREATED: SPACE_INVITE_CREATED,
      UPDATED: SPACE_INVITE_UPDATED,
      REMOVED: SPACE_INVITE_REMOVED,
    });

    return store;
  };

export default realtimeStoreEnhancer;
