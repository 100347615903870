const io = require('socket.io-client');
const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const auth = require('@feathersjs/authentication-client');
const rest = require('@feathersjs/rest-client');
const fetch = require('isomorphic-unfetch');
import { API_ROOT } from '../components/config';

export const makeApi = (useSocketClient = false) => {
  const app = feathers();

  if (useSocketClient) {
    const socket = io(API_ROOT);
    app.configure(
      socketio(socket, {
        timeout: 30000,
      })
    );
  } else {
    const restClient = rest(API_ROOT);
    app.configure(restClient.fetch(fetch));
  }

  app.configure(auth({ scheme: '', storage: new auth.MemoryStorage() }));

  return app;
};

// by default
// on server, use rest
// on browser, use websocket
const useSocketIO = process.browser;
let app = makeApi(useSocketIO);

export default app;
